import React from "react"
import Layout from "@layouts/layout"
import { graphql } from "gatsby"
import * as FirebaseComments from "../components/Comments"
import * as Recipe from "../components/recipe/"
import style from "../components/recipe/recipe.mod.scss"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { ProtectedRoute } from "../components/ProtectedRoute"
import { useAuth0 } from "@utils/auth"
import PropTypes from "prop-types"

const RecipeDetail = ({
  data: { recipe, content },
  pageContext: { parent, language }
}) => {
  const { loading, user } = useAuth0()

  const {
    id,
    originalId,
    title,
    mealTypes,
    dietaryTypes,
    heroImage,
    ingredients,
    directions,
    prepTime,
    cookTime,
    serves,
    notesNode,
    showComments = false
  } = recipe

  const { ingredientsHeading, methodHeading, perfectFor } = content.recipe

  const filterTypes = [
    "Main Meals",
    "Light Meals",
    "Desserts",
    "Snacks",
    "Breakfasts"
  ]

  const filteredTypes = mealTypes
    .filter(({ namePlural }) => filterTypes.indexOf(namePlural) > 0)
    .map(({ namePlural }) => namePlural)

  const badges = dietaryTypes.map(item => ({
    svg: item.badge.url,
    label: item.name
  }))

  return (
    <ProtectedRoute>
      <Layout parent={parent}>
        <HelmetDatoCms
          defer={false}
          seo={recipe.seoMetaTags}
          language={language}
        />

        <RecipeWrapper>
          <div className={style.recipe__wrapper}>
            <Recipe.Banner badges={badges} heroImage={heroImage} />
            <Recipe.Info
              originalId={originalId}
              id={id}
              title={title}
              filteredTypes={filteredTypes}
              perfectFor={perfectFor}
              prepTime={prepTime}
              cookTime={cookTime}
              serves={serves}
            />
          </div>
          <Recipe.ContentComponent
            ingredientsHeading={ingredientsHeading}
            ingredients={ingredients}
            methodHeading={methodHeading}
            directions={directions}
          />
          <Recipe.Notes notesNode={notesNode} />

          {showComments && (
            <Recipe.RecipeComments>
              <FirebaseComments.List id={originalId} />
              <FirebaseComments.SubmitHeader
                text="Submit a Comment"
                loggedAs={user?.name}
              />
              <FirebaseComments.SubmitForm id={id} originalId={originalId} />
            </Recipe.RecipeComments>
          )}
        </RecipeWrapper>
      </Layout>
    </ProtectedRoute>
  )
}

export const query = graphql`
  query recipeById($id: String) {
    recipe: datoCmsRecipe(id: { eq: $id }) {
      ...Recipe
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      recipe {
        ingredientsHeading
        methodHeading
        perfectFor
      }
    }
  }
`

export default RecipeDetail

RecipeDetail.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  originalId: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  mealTypes: PropTypes.array,
  dietaryTypes: PropTypes.array,
  ingredients: PropTypes.array.isRequired,
  directions: PropTypes.array.isRequired,
  prepTime: PropTypes.string.isRequired,
  cookTime: PropTypes.string.isRequired,
  serves: PropTypes.string.isRequired,
  showComments: PropTypes.bool
}

export const RecipeWrapper = ({ children }) => {
  return <div className={style.recipe}>{children}</div>
}
