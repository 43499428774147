import React from "react"
import Block from "@global/layout/Block"
import Content from "@elements/Content"

export const Footer = ({ notesNode }) => {
  return (
    <Block desktopPadding="top-double" gutters={true} maxWidth="inner">
      <Content html={notesNode.childMarkdownRemark.html} />
    </Block>
  )
}

export default Footer
