import React from "react"
import style from "./icon.mod.scss"
import Cook from "@svgs/recipe-time.svg"
import Prep from "@svgs/recipe-prep.svg"
import Serves from "@svgs/recipe-serving.svg"
import Drop from "@svgs/smallDrop.svg"
import PropTypes from "prop-types"

const Icon = ({ type, subText, content, modifier }) => {
  const IconType = () => {
    if (type === "prep") {
      return <Prep />
    } else if (type === "cook") {
      return <Cook />
    } else if (type === "serves") {
      return <Serves />
    } else if (type === "drop") {
      return <Drop />
    } else {
      return null
    }
  }

  return (
    <div className={[style.icon, modifier].join(" ")}>
      <div className={style.icon__svg}>
        <IconType />
      </div>
      <div className={style.icon__content}>
        <p className={style.icon__text}>{content}</p>
        {subText && <p className={style.icon__sub}>{subText}</p>}
      </div>
    </div>
  )
}

export default Icon

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subText: PropTypes.string,
  modifier: PropTypes.string
}
