import React from "react"
import style from "./recipe.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Badges from "@elements/Badges"

export const Banner = ({ heroImage, badges }) => {
  return (
    <div className={style.recipe__hero}>
      <GatsbyImage
        className={style.recipe__image}
        image={heroImage.gatsbyImageData}
      />
      <div className={style.recipe__badges}>
        <Badges badgeList={badges} />
      </div>
    </div>
  )
}
export default Banner
