import React from "react"
import FavouriteHeartButton from "@global/cards/FavouriteHeartButton"
import style from "./recipe.mod.scss"
import Icon from "@elements/Icon"
import { useAuth0 } from "@utils/auth"
import Block from "@global/layout/Block"

export const Info = ({
  id,
  originalId,
  filteredTypes,
  perfectFor,
  prepTime,
  cookTime,
  serves,
  title,
  workout
}) => {
  const {
    addFavouriteRecipes,
    favouriteRecipes,
    removeFavouriteRecipes
  } = useAuth0()

  return (
    <div className={style.recipe__upper}>
      <Block padding="double-top" gutters={true} maxWidth="inner">
        <div className={style.recipe__fav}>
          <h1 className={style.recipe__title}>{title}</h1>
          <FavouriteHeartButton
            id={originalId}
            onAddFavourite={addFavouriteRecipes}
            onRemoveFavourite={removeFavouriteRecipes}
            favouriteArray={favouriteRecipes}
            large={true}
          />
        </div>
        {filteredTypes.length > 0 && (
          <p className={style.recipe__perfect}>
            {perfectFor} {filteredTypes.join(", ")}
          </p>
        )}
        <div className={style.recipe__icons}>
          <Icon
            key={"icon-prep"}
            type="prep"
            subText="Prep"
            unit="min"
            content={prepTime}
          />
          <Icon
            key={"icon-cook"}
            type="cook"
            subText="Cook"
            unit="min"
            content={cookTime}
          />
          <Icon
            key={"icon-serves"}
            type="serves"
            subText="Serves"
            content={serves}
          />
        </div>
      </Block>
    </div>
  )
}
export default Info
