import React, { Fragment } from "react"
import style from "./recipe.mod.scss"
import Content from "@elements/Content"
import Block from "@global/layout/Block"

export const ContentComponent = ({
  ingredientsHeading,
  ingredients,
  methodHeading,
  directions
}) => {
  return (
    <Block padding="top" gutters={true} maxWidth="inner">
      <div className={style.recipe__lower}>
        <div className={style.recipe__ingredients}>
          <h3>{ingredientsHeading}</h3>
          {ingredients.map(({ id, subheading, ingredientsNode }) => {
            return (
              <Fragment key={`inst-${id}`}>
                {subheading && <h4>{subheading}</h4>}
                <Content html={ingredientsNode.childMarkdownRemark.html} />
              </Fragment>
            )
          })}
        </div>
        <div className={style.recipe__directions}>
          <h3>{methodHeading}</h3>
          {directions.map(({ id, subheading, directionsNode }) => {
            return (
              <Fragment key={`meth-${id}`}>
                {subheading && <h4>{subheading}</h4>}
                <Content html={directionsNode.childMarkdownRemark.html} />
              </Fragment>
            )
          })}
        </div>
      </div>
    </Block>
  )
}

export default ContentComponent
